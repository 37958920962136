import { InputHTMLAttributes, ReactElement } from 'react';
import { StyledInput } from './styled-input';
import { StyledLabel, StyledLabelProps } from './styled-label';
import { StyledValidationMessage, StyledValidationMessageProps } from './styled-validation-message';

const pattern = '^#[A-Fa-f0-9]{6}$';

type Props = InputHTMLAttributes<HTMLInputElement> & StyledLabelProps & StyledValidationMessageProps;

export const StyledColorInput = ({
  error,
  id,
  label,
  onBlur,
  onChange,
  required = true,
  value,
}: Props): ReactElement => {
  return (
    <div className="styled-color-input w-max" data-testid={`styled-color_input-${id}`}>
      {label && <StyledLabel {...label} className="!mb-[4px]" htmlFor={id} required={!!required} />}
      <div className="flex gap-[8px]">
        <StyledInput
          className="!w-[96px]"
          name={id}
          onBlur={onBlur}
          onChange={onChange}
          pattern={pattern}
          required={!!required}
          title={id}
          type="text"
          value={value}
        />
        <input
          id={id}
          type="color"
          onBlur={onBlur}
          onChange={onChange}
          pattern={pattern}
          required={!!required}
          title={id}
          value={value}
        />
      </div>
      {error && <StyledValidationMessage className="mt-[6px]" {...(id && { id })} message={error} />}
    </div>
  );
};

export default StyledColorInput;
