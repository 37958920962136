import classnames from 'classnames';
import { ForwardedRef, InputHTMLAttributes, ReactElement, forwardRef, useEffect, useState } from 'react';
import { StyledLabel, StyledLabelProps } from './styled-label';
import { StyledMaxCharactersSpecification, StyledMaxCharsSpecProps } from './styled-max-characters-specification';
import { StyledValidationMessage, StyledValidationMessageProps } from './styled-validation-message';

type Props = {
  /**
   * We have to override the existing unwanted styles with a high specificity selector.
   * This means additional selector properties outside the current specificity need to be applied as '!important'.
   * Tailwind CSS classes need to by applied with the '!' prefix.
   */
  className?: string;
} & InputHTMLAttributes<HTMLInputElement> &
  StyledLabelProps &
  StyledMaxCharsSpecProps &
  StyledValidationMessageProps;

export const StyledInput = forwardRef<HTMLInputElement, Props>(
  (
    { className, error, id, label, maxLength, maxLengthVisible, onChange, required = true, ...rest }: Props,
    ref: ForwardedRef<HTMLInputElement>,
  ): ReactElement => {
    const [currentLength, setCurrentLength] = useState<number>((rest.value as any)?.length || 0);

    const onChangeWrapper = (e: React.ChangeEvent<HTMLInputElement>): void => {
      !rest.value && setCurrentLength(e.target.value.length || 0); // sets char count for uncontrolled component
      onChange?.(e);
    };

    useEffect((): void => {
      !!rest.value && setCurrentLength((rest.value as any)?.length); // sets char count for controlled component
    }, [rest.value]);

    const props = {
      ...rest,
      className: classnames('styled-input', className),
      id,
      maxLength,
      ...{ onChange: maxLengthVisible ? onChangeWrapper : onChange },
      required,
      ref,
    };

    const input = <input {...props} />;

    return (
      <>
        {label && <StyledLabel {...label} className="!mb-[4px]" htmlFor={id} required={!!required} />}
        {input}
        {maxLengthVisible && (
          <StyledMaxCharactersSpecification
            className="mt-[6px]"
            currentLength={currentLength}
            maxLength={maxLength as number}
          />
        )}
        {error && <StyledValidationMessage {...(id && { id })} message={error} />}
      </>
    );
  },
);

export type { Props as StyledInputProps };
export default StyledInput;
