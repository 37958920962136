import classnames from 'classnames';
import { ReactElement } from 'react';

export type StyledValidationMessageProps = {
  error?: string;
};

type Props = {
  className?: string;
  id?: string;
  message: string;
  showErrorLabel?: boolean;
};

export const StyledValidationMessage = ({ className, id, message, showErrorLabel = false }: Props): ReactElement => {
  const classNames = classnames('styled-validation_message text-error text-14', className);

  return (
    <div className={classNames} {...(id && { 'data-testid': `${id}-validation-message` })}>
      {showErrorLabel ? <span className="font-bold">Error: </span> : ''}
      {message}
    </div>
  );
};

export default StyledValidationMessage;
