import classnames from 'classnames';
import { useFormikContext } from 'formik';
import { PropsWithChildren } from 'react';
import { StyledLabel } from './styled-label';
import { GenericFormInputProps } from './types';

export type FormInputContainerProps = PropsWithChildren<
  GenericFormInputProps & { error?: string; errorPosition?: 'before' | 'after'; touched?: boolean; htmlFor?: string }
>;

export const FormInputContainer = ({
  containerClassName = 'form-input-container',
  containerTestId,
  name,
  label,
  labelClassName,
  required,
  tooltip,
  optional,
  testId,
  children,
  renderBefore,
  renderAfter,
  error,
  touched,
  errorPosition = 'after',
  htmlFor,
  displayValidInput = true,
}: FormInputContainerProps) => {
  const { submitCount } = useFormikContext();
  const isValid = (submitCount > 0 || touched) && !error;
  const hasError = (submitCount > 0 || touched) && error;
  const errorJsx = hasError ? (
    <span className="error-messages !p-0">
      {typeof error === 'object' ? Object.values(error).join('. ') : error?.toString()}
    </span>
  ) : null;

  return (
    <div
      className={classnames(containerClassName, {
        'has-error': hasError,
        'is-valid': isValid,
        'no-success-color': displayValidInput,
      })}
      data-testid={containerTestId || (testId ? `${testId}-container` : undefined)}
    >
      {renderBefore || null}

      {label && (
        <StyledLabel htmlFor={htmlFor} required={!optional} tooltip={tooltip} className={labelClassName}>
          {label}
        </StyledLabel>
      )}

      {errorJsx && errorPosition === 'before' && errorJsx}

      {children}

      {renderAfter || null}

      {errorJsx && errorPosition === 'after' && errorJsx}
    </div>
  );
};
