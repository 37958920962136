import classnames from 'classnames';
import { ReactElement } from 'react';
import { Trans } from 'react-i18next';

export type StyledMaxCharsSpecProps =
  | {
      maxLengthVisible?: never;
    }
  | {
      maxLengthVisible: true;
      maxLength: number;
    };

type Props = {
  className?: string;
  currentLength: number;
  maxLength: number;
};

export const StyledMaxCharactersSpecification = ({ className, currentLength, maxLength }: Props): ReactElement => {
  const classNames = classnames('styled-max_char_length_spec flex justify-between leading-none', className);

  return (
    <div className={classNames}>
      <div className="font-light text-gray-300">
        <Trans i18nKey="maxLengthSpec" values={{ maxLength }} />
      </div>
      {currentLength}
    </div>
  );
};

export default StyledMaxCharactersSpecification;
