import classnames from 'classnames';
import React, {
  ButtonHTMLAttributes,
  FC,
  MouseEventHandler,
  PropsWithChildren,
  forwardRef,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { Icon } from '../icon/icon';
import { AiOutlineLoading } from 'react-icons/ai';

export enum ButtonSize {
  Small = 'small',
  Large = 'large',
}

export enum ButtonColor {
  Primary = 'primary',
  Secondary = 'secondary',
  Cancel = 'cancel',
  Draft = 'draft',
  Complete = 'complete',
  Success = 'success',
  Remove = 'remove',
  Orange = 'orange',
  White = 'white',
  Blue = 'blue',
  Purple = 'purple',
}

export enum ButtonPull {
  Left = 'left',
  Right = 'right',
  Center = 'center',
}

export enum ButtonVariant {
  Button = 'button',
  Text = 'text',
  Icon = 'icon',
  Ghost = 'ghost',
}

export type ButtonProps = Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick' | 'color'> & {
  testId?: string;
  variant?: ButtonVariant | `${ButtonVariant}`;
  size?: ButtonSize;
  title?: string;
  color?: ButtonColor | `${ButtonColor}`;
  pull?: ButtonPull;
  fullWidth?: boolean;
  onClick?: () => void | Promise<void | unknown>;
  onClickError?: (err: string) => void;
  showOnClickError?: boolean;
  showOnClickErrorDuration?: number;
  successMessage?: string | React.ReactNode;
  successMessageDuration?: number;
  secondaryButtons?: React.ReactNode[];

  loading?: boolean;
  showLoader?: boolean;
  showChildrenOnLoading?: boolean;
  loaderComponent?: FC | string;
  loaderPosition?: 'start' | 'end';
};

let successMessageTimeout: NodeJS.Timeout;
let errorTimeout: NodeJS.Timeout;

const Loader = () => {
  return <Icon className="button-loader" icon={AiOutlineLoading} />;
};

const DropdownContainer = ({ children }: PropsWithChildren) => {
  return <div className="dropdown-container">{children}</div>;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      size,
      color,
      pull,
      fullWidth,
      className,
      onClick: onClickProp,
      onClickError,
      showOnClickError = false,
      showOnClickErrorDuration = 2000,
      children,
      loading: loadingOverride,
      showLoader = false,
      showChildrenOnLoading = true,
      loaderPosition = 'end',
      title,
      variant = ButtonVariant.Button,
      loaderComponent: LoaderComponent = Loader,
      testId,
      secondaryButtons,
      successMessage,
      successMessageDuration = 2000,
      ...props
    },
    ref,
  ) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [loadingState, setLoadingState] = useState(false);
    const [error, setError] = useState<string | undefined>();

    useEffect(() => {
      return () => {
        if (successMessageTimeout) {
          setShowSuccessMessage(false);
          clearTimeout(successMessageTimeout);
        }

        if (errorTimeout) {
          setError(undefined);
          clearTimeout(errorTimeout);
        }
      };
    }, []);

    const hideSuccessMessage = useCallback(() => {
      setShowSuccessMessage(false);
    }, [setShowSuccessMessage]);

    const clearError = useCallback(() => {
      setError(undefined);
    }, [setShowSuccessMessage]);

    const onError = useCallback(
      (e: Error) => {
        console.error('Button onClick error', e);
        setShowSuccessMessage(false);
        setLoadingState(false);
        setError(e.message || e.toString() || 'Unknown error');
        if (onClickError) {
          onClickError(e.message || e.toString() || 'Unknown error');
        }

        if (showOnClickError) {
          errorTimeout = setTimeout(clearError, showOnClickErrorDuration);
        }
      },
      [onClickError, setShowSuccessMessage, setLoadingState],
    );

    const onClick = onClickProp
      ? (e: React.MouseEvent) => {
          e.preventDefault();
          e.stopPropagation();
          setError(undefined);

          try {
            const result = onClickProp();
            if (result instanceof Promise) {
              setLoadingState(true);
              result
                .then((result) => {
                  if (successMessage) {
                    setShowSuccessMessage(true);
                    successMessageTimeout = setTimeout(hideSuccessMessage, successMessageDuration);
                  }

                  return result;
                })
                .catch(onError)
                .finally(() => setLoadingState(false));
            }
          } catch (e) {
            onError(e as Error);
          }
        }
      : undefined;

    const classes = ['button'];
    if (size) classes.push(`button-${size}`);
    if (color) classes.push(`button-${color}`);
    if (pull) classes.push(`u-pull-${pull}`);
    if (fullWidth) classes.push('u-full-width');
    if (variant && variant !== ButtonVariant.Button) classes.push(`button-${variant}`);
    if (secondaryButtons && secondaryButtons.length > 0) classes.push('button-with-dropdown');
    if (className) classes.push(className);

    const loading = loadingOverride || loadingState;

    const openDropdown: MouseEventHandler = useCallback(
      (e) => {
        e?.preventDefault();
        e?.stopPropagation();

        setDropdownOpen(!dropdownOpen);
      },
      [dropdownOpen],
    );

    const onClickWindow = useCallback(() => {
      if (dropdownOpen) {
        setDropdownOpen(false);
      }
    }, [dropdownOpen]);

    useEffect(() => {
      if (dropdownOpen) {
        window.addEventListener('click', onClickWindow);
      }

      return () => {
        window.removeEventListener('click', onClickWindow);
      };
    }, [dropdownOpen, onClickWindow]);

    const Container = secondaryButtons ? DropdownContainer : React.Fragment;

    return (
      <Container>
        <button
          ref={ref}
          onClick={onClick}
          disabled={loading || props.disabled || showSuccessMessage}
          title={title}
          aria-label={title}
          data-testid={testId}
          {...props}
          className={classes.join(' ')}
        >
          {loading && showLoader && loaderPosition === 'start' ? (
            typeof LoaderComponent === 'string' ? (
              LoaderComponent
            ) : (
              <LoaderComponent />
            )
          ) : null}

          {error && showOnClickError ? (
            <span className="button-error">{error}</span>
          ) : (
            <>
              {!loading || showChildrenOnLoading ? (showSuccessMessage ? successMessage : children) : null}

              {loading && showLoader && loaderPosition === 'end' ? (
                typeof LoaderComponent === 'string' ? (
                  LoaderComponent
                ) : (
                  <LoaderComponent />
                )
              ) : null}

              {secondaryButtons ? (
                <Icon className="button-dropdown-icon" icon={FaChevronDown} onClick={openDropdown} />
              ) : null}
            </>
          )}
        </button>
        {secondaryButtons ? (
          <div className={classnames('button-dropdown', { open: dropdownOpen })}>{secondaryButtons}</div>
        ) : null}
      </Container>
    );
  },
);
