import { CSSProperties, FC, ReactNode, useState } from 'react';
import cs from 'classnames';
import { Button } from '../button/button';
import { BsChevronDown } from 'react-icons/bs';
import { useTranslation } from 'next-i18next';

type Props = {
  children: ReactNode;
  maxHeight?: string;
  defaultExpanded?: boolean;
  retractable?: boolean;
};

export const Expandable: FC<Props> = ({ maxHeight = false, children, defaultExpanded = false, retractable = true }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(defaultExpanded);
  const toggle = () => setExpanded((current) => !current);
  const style = {
    ...(maxHeight && { '--expandable-height': maxHeight }),
  } as CSSProperties;
  const shouldDisplayButton = !expanded || retractable;
  return (
    <div className="w-full flex flex-col">
      <div style={style} className={cs('expandable relative', { expanded })}>
        {children}
      </div>
      {shouldDisplayButton && (
        <Button
          variant="text"
          color="blue"
          onClick={toggle}
          className={cs('!text-16 mx-auto !flex gap-[8px]', !expanded && '-top-[12px]')}
        >
          <span>{expanded ? t('seeLess') : t('seeMore')}</span>
          <BsChevronDown
            size={16}
            className={cs('transition-transform my-auto', expanded ? 'rotate-180' : 'rotate-0')}
          />
        </Button>
      )}
    </div>
  );
};
