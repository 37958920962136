import classnames from 'classnames';
import { ForwardedRef, ReactElement, ReactNode, SelectHTMLAttributes, forwardRef } from 'react';
import { useTranslation } from 'next-i18next';
import { StyledLabel, StyledLabelProps } from './styled-label';
import { StyledValidationMessage, StyledValidationMessageProps } from './styled-validation-message';

type SelectBaseProps = {
  /**
   * We have to override the existing unwanted styles with a high specificity selector.
   * This means additional selector properties outside the current specificity need to be applied as '!important'.
   * Tailwind CSS classes need to by applied with the '!' prefix.
   */
  className?: string;
  includeEmptyOption?: boolean;
} & SelectHTMLAttributes<HTMLSelectElement> &
  StyledValidationMessageProps &
  StyledLabelProps;

type Props = (
  | {
      children?: never;
      options: Record<string, string> | Array<string>;
      optionGroups?: never;
    }
  | {
      children?: never;
      options?: never;
      optionGroups: Record<string, Record<string, string> | Array<string>>;
    }
  | {
      children: ReactNode | undefined;
      options?: never;
      optionGroups?: never;
    }
) &
  SelectBaseProps;

const SelectBase = forwardRef<HTMLSelectElement, SelectBaseProps>(
  (
    { children, className, includeEmptyOption = true, ...rest }: SelectBaseProps,
    ref: ForwardedRef<HTMLSelectElement>,
  ): ReactElement => {
    const { t } = useTranslation();

    const cssClasses = classnames('styled-select', className);

    return (
      <select className={cssClasses} {...rest} ref={ref}>
        {includeEmptyOption && <option value="">{`-- ${t('select').toLowerCase()} --`}</option>}
        {children}
      </select>
    );
  },
);

export const StyledSelect = forwardRef<HTMLSelectElement, Props>(
  (
    { children, error, id, label, options, optionGroups, required = true, value, ...rest }: Props,
    ref: ForwardedRef<HTMLSelectElement>,
  ): ReactElement => {
    let renderOptions: ReactNode;

    if (options) {
      const useLabel = options instanceof Array;
      renderOptions = Object.entries(options)
        .filter(([_, label]: [string, string]): boolean => !!label)
        .map(([value, label]: [string, string]): ReactElement => {
          const val = useLabel ? label : value;
          return (
            <option key={val} value={val}>
              {label}
            </option>
          );
        });
    } else if (optionGroups) {
      renderOptions = Object.entries(optionGroups).map(
        ([groupLabel, groupOptions]: [string, Record<string, string> | Array<string>]): ReactElement => {
          const useLabel = groupOptions instanceof Array;
          return (
            <optgroup key={groupLabel} label={groupLabel}>
              {Object.entries(groupOptions).map(([value, label]: [string, string]): ReactElement => {
                const val = useLabel ? label : value;
                return (
                  <option key={val} value={val}>
                    {label}
                  </option>
                );
              })}
            </optgroup>
          );
        },
      );
    }

    return (
      <>
        {label && <StyledLabel {...label} className="!mb-[4px]" htmlFor={id} required={!!required} />}
        <SelectBase {...rest} id={id} ref={ref} {...(value && { value })}>
          {children || renderOptions}
        </SelectBase>
        {error && <StyledValidationMessage {...(id && { id })} message={error} />}
      </>
    );
  },
);

export type { Props as StyledSelectProps };
export default StyledSelect;
