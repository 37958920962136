import { FC } from 'react';
import { Alert, AlertProps } from './alert';
import { IoAlertCircleOutline } from 'react-icons/io5';

type StyledAlertProps = Pick<AlertProps, 'title' | 'message' | 'className' | 'action'> & {
  hideIcon?: boolean;
} & (
    | {
        dismissKey: string;
        onDismiss?: never;
      }
    | {
        dismissKey?: never;
        onDismiss: () => void;
      }
    | {
        dismissKey?: never;
        onDismiss?: never;
      }
  );

export const InfoAlert: FC<StyledAlertProps> = ({ hideIcon = false, ...props }) => {
  return <Alert severity="info" icon={hideIcon ? undefined : <IoAlertCircleOutline />} {...props} />;
};

export const ErrorAlert: FC<StyledAlertProps> = ({ hideIcon = false, ...props }) => {
  return <Alert severity="error" icon={hideIcon ? undefined : <IoAlertCircleOutline />} {...props} />;
};

export const SuccessAlert: FC<StyledAlertProps> = ({ hideIcon = false, ...props }) => {
  return <Alert severity="success" icon={hideIcon ? undefined : <IoAlertCircleOutline />} {...props} />;
};
