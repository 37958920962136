import { Formik, FormikProps } from 'formik';
import { ReactElement } from 'react';
import { useTranslation } from 'next-i18next';
import { object, string } from 'yup';
import { StyledButton } from '../../core/button/styled-button';
import { StyledInput } from '../../core/form/styled-input';
import { Grid } from '../../core/layout/grid/grid';
import { UseCreateFolderOpts } from '../hooks/useCreateFolder';
import { MediaBackendAdapter } from '../types';

export type CreateMediaFolderFormProps = Pick<UseCreateFolderOpts, 'onFolderCreated'> & {
  parentId: string | null;
  backendAdapter: MediaBackendAdapter;
};

export const CreateMediaFolderForm = ({ parentId, backendAdapter, onFolderCreated }: CreateMediaFolderFormProps) => {
  const { t } = useTranslation();
  const { error, createFolder } = backendAdapter.useCreateFolder({ onFolderCreated });

  const validationSchema = object().shape({
    name: string()
      .min(3)
      .max(20)
      .matches(/^[a-zA-Z0-9]{3,20}$/)
      .required()
      .label(t('field')),
  });

  return (
    <Grid.Container className="media-manager-form--create" fluid>
      <Grid.Row>
        <Grid.Column colspan={12}>
          <h3>{t('mediaManager.createFolder')}</h3>
        </Grid.Column>
      </Grid.Row>

      {error && (
        <Grid.Row className="error-messages">
          <Grid.Column colspan={12}>
            <p>{error}</p>
          </Grid.Column>
        </Grid.Row>
      )}
      <Grid.Row>
        <Grid.Column colspan={12}>
          <Formik initialValues={{ name: '', parentId }} validationSchema={validationSchema} onSubmit={createFolder}>
            {({
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              touched,
            }: FormikProps<{ name: string; parentId: string | null }>): ReactElement => (
              <form onSubmit={handleSubmit}>
                <StyledInput
                  error={touched.name && errors.name ? errors.name : undefined}
                  id="name"
                  label={{
                    children: t('mediaManager.folderName'),
                  }}
                  maxLength={20}
                  maxLengthVisible
                  minLength={3}
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <StyledButton buttonType="primary" disabled={!!errors.name || isSubmitting} type="submit">
                  {t('mediaManager.createFolder')}
                </StyledButton>
              </form>
            )}
          </Formik>
        </Grid.Column>
      </Grid.Row>
    </Grid.Container>
  );
};
