import React from 'react';
import { MediaItemResponse } from '../types';
import { Grid } from '../../core/layout/grid/grid';
import { Button, ButtonColor, ButtonPull, ButtonSize } from '../../core/button/button';
import { useTranslation } from 'next-i18next';
import { Modal } from '../../core/modal/modal';

export interface MediaItemPreviewModalProps {
  item: MediaItemResponse;
  onInsertMediaItem?: () => void;
  onRemoveMediaItem?: (item: MediaItemResponse) => void;
  onClose: () => void;
}

const bytesToSize = (bytes: number) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString(), 10);
  return `${Math.round(bytes / Math.pow(1024, i))} ${sizes[i]}`;
};

export function MediaItemPreviewModal({
  item,
  onClose,
  onInsertMediaItem,
  onRemoveMediaItem,
}: MediaItemPreviewModalProps) {
  const { t } = useTranslation();
  if (!item) {
    return null;
  }
  const canDelete = !!onRemoveMediaItem; // TODO: Add localization

  return (
    <Modal open={true} onClose={onClose} modal={false}>
      <Grid.Container fluid={true}>
        <Grid.Row>
          <Grid.Column colspan={12}>
            <h3>{item.fileName}</h3>
            <p>
              {t('mediaManager.size')}: {item.width}x{item.height} ({bytesToSize(item.contentLength)})
            </p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column colspan={12}>
            <img className="fluid" src={item.publicUrl} alt={item.name} />
            <div className="flex flex-row flex-1 justify-end justify-self-end gap-2">
              {canDelete ? (
                <Button
                  onClick={() => onRemoveMediaItem(item)}
                  pull={ButtonPull.Right}
                  color={ButtonColor.Remove}
                  size={ButtonSize.Small}
                >
                  {t('mediaManager.deleteMedia')}
                </Button>
              ) : null}
              {onInsertMediaItem ? (
                <Button
                  onClick={onInsertMediaItem}
                  pull={ButtonPull.Right}
                  color={ButtonColor.Complete}
                  size={ButtonSize.Small}
                >
                  {t('mediaManager.insertImage')}
                </Button>
              ) : null}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid.Container>
    </Modal>
  );
}
