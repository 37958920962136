import { MediaItemResponse } from '../types';
import React, { MouseEventHandler } from 'react';

export interface MediaItemGridProps {
  items: MediaItemResponse[];
  onClickMediaItem: (item: MediaItemResponse) => void;
}

export function MediaItemGrid({ items, onClickMediaItem }: MediaItemGridProps) {
  if (!items.length) {
    return null;
  }

  const onClick =
    (item: MediaItemResponse): MouseEventHandler =>
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      onClickMediaItem(item);
    };

  return (
    <div className="media-items tw-grid grid grid-cols-12 gap-4" data-testid="media-items">
      {items.map((item) => (
        <div
          key={item._id}
          className="col-span-6 sm:col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-3"
          onClick={onClick(item)}
        >
          <div className="media-item" data-testid="media-item">
            <img src={item.publicUrl} alt={item.name} />
            <a onClick={onClick(item)} href="#">
              {item.name}
            </a>
          </div>
        </div>
      ))}
    </div>
  );
}
