import React, { PropsWithChildren, useEffect } from 'react';
import classnames from 'classnames';
import * as Portal from '@radix-ui/react-portal';
import { useDelayedValue } from '../../../hooks/useDelayedValue';
import { MdClose } from 'react-icons/md';

export const BottomSheet = ({
  children,
  isOpen,
  onClose,
}: PropsWithChildren<{ isOpen: boolean; onClose: () => void }>) => {
  const delayedIsOpen = useDelayedValue(isOpen, 50);

  useEffect(() => {
    let scrollY = 0;
    if (isOpen) {
      scrollY = window.scrollY;
      window.scrollTo(0, 0);
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'auto';
      window.scrollTo(0, scrollY);
    };
  }, [isOpen]);

  if (!delayedIsOpen && !isOpen) {
    return null;
  }

  return (
    <Portal.Root>
      <div
        className={classnames(
          'absolute flex top-0 bottom-0 left-0 right-0 m-0 p-0 max-w-screen max-h-screen z-[1010] pointer-events-auto',
          'bg-gray-75',
          'transition-all duration-1000 ease-in-out',
          {
            'opacity-100 bg-opacity-70': delayedIsOpen && isOpen,
            'opacity-0 bg-opacity-0': !delayedIsOpen || !isOpen,
          },
        )}
        onClick={(e) => {
          e.stopPropagation();
          onClose();
        }}
      >
        <div
          className={classnames(
            'flex flex-col h-full max-h-full w-full max-w-full md:max-w-[400px] md:w-fit md:h-fit md:m-auto',
            'transition-all duration-1000 ease-in-out',
            {
              '': delayedIsOpen && isOpen,
              'mt-[100vh] md:mt-[100vh]': !delayedIsOpen || !isOpen,
            },
          )}
        >
          <div className="w-full h-[75px] visible md:hidden" />
          <div
            className="flex flex-col flex-1 relative bg-white overflow-hidden rounded-t-[20px] md:rounded-[20px] shadow-dark"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <MdClose className="absolute top-4 right-4 text-blue-800 text-4xl p-5 pointer" onClick={onClose} />
            {children}
          </div>
        </div>
      </div>
    </Portal.Root>
  );
};
